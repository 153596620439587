import Terms from '@/templates/Terms';
import React, { FC } from 'react';

import Layout from '../components/Layout/Layout';

const TermsPolicy: FC = () => (
  <div>
    <Layout>
      <Terms />
    </Layout>
  </div>
);
export default TermsPolicy;
