import FooterOptions from '@/components/FooterOptions';
import React, { FC, ReactElement } from 'react';

import './Terms.scss';

const Cookies: FC = (): ReactElement => (
  <>
    <FooterOptions
      className="terms footerOptions"
      Header="Motokiki's Terms and Conditions"
      SubHeader="These terms and conditions were last updated on 5th April 2019."
    />

    <div className="termsPageContainer">
      <h2>Who we are</h2>
      <p>We are Motokiki, a company incorporated under Motokiki Ltd.</p>
      <p>
        Our registered office is Staverton Court, Staverton, Cheltenham,
        Gloucestershire, United Kingdom, GL51 0UX.
      </p>
      <p>
        Throughout these terms when we mention “we”, “us” or “our”, we mean
        Motokiki. When referring to “you” we mean the user of this website or
        anyone using our service.
      </p>
      <h4>Terms and conditions explained</h4>
      <p>
        These are the terms and conditions for using{` `}
        <a href="https://www.motokiki.com/">www.motokiki.com</a>. The terms make
        a legally binding contract between us as a company and you as a user. 
        Your acceptance of these terms and conditions is made by you using this
        website and is dated from your first use of this website.  If you do not
        accept the terms and conditions or any part of them you should exit and
        stop using this website.
      </p>
      <h4>Adding links to our site</h4>
      <p>
        Before providing a link to this website we ask you seek our permission.
        To do this, e-mail{` `}
        <a href="mailto:dpo@motokiki.com">dpo@motokiki.com</a> with the details
        of the request.
      </p>
      <h4>Copyright</h4>
      <p>
        When referring to “Website Information” in these terms and conditions we
        mean any graphics, text, images, videos, applets and scripts operating,
        operating in, or which form part of, this website.
      </p>
      <p>
        All website information displayed on{` `}
        <a href="https://www.motokiki.com/">www.motokiki.com</a> is owned by
        Motokiki. Feel free to view website information however you may not
        duplicate the website information or any part of it without requesting
        permission from Motokiki. All written requests can be submitted to ‘The
        Marketing Team’ at the above fixed address. Each case will be looked at
        individually and decisions are at Motokiki’s discretion.  In cases where
        we do not own the copyright we will direct you to the owner themselves
        for permission.
      </p>
      <h4>Accuracy</h4>
      <p>
        Our website information is provided “as is” and do not make any
        endorsement, representation or warranty as to its accuracy.  We cannot
        be liable for any action taken (or not taken) in reliance upon the
        website information and such action is taken at your own risk. We always
        reserve the right to amend the website information without liability or
        notice to you.
      </p>
      <p>
        All partner website links are not controlled by us and we cannot take
        responsibility for them. We are not liable in any way for their content.
      </p>
      <p>
        As with all websites, they are susceptible to vandalism and
        cyber-squatting. We cannot be liable or accept any responsibility for
        any content or information which appears on this website as a result of
        such actions.
      </p>
      <p>
        The operation and use of service of this website does depend on the
        input and accuracy of information entered by you. We cannot be liable or
        therefore accept any responsibility for the input of inaccurate
        information to this website by you and/or any third parties.
      </p>
      <p>
        Should you have any complaints about what we have published or you
        believe that our information is inaccurate please email{` `}
        <a href="mailto:Marketing@motokiki.com">Marketing@motokiki.com</a>. We
        will investigate such requests on receipt and take necessary action
        which we, in our sole discretion, determine as being appropriate having
        regard to all the circumstances.
      </p>
      <h4>Images</h4>
      <p>
        The tyre images used on our website are for illustrative purposes only
        and with some tyres and/or tread patterns may vary depending on the size
        of the tyre.
      </p>
      <h4>Internet usage</h4>
      <p>About the internet, service interruption, viruses etc.</p>
      <p>
        As with all other web publishers we have no control over the internet.
        The internet is a global public network of computers and the method by
        which users access <a href="http://Motokiki.com">Motokiki.com</a>. As a
        consequence and in common with most web publishers we take no
        responsibility for service interruption or the transmission of viruses
        or other malicious computer code through this website.
      </p>
      <h4>About personal information and our privacy policy</h4>
      <p>
        At Motokiki we care about your privacy, and have an individual policy to
        relate to our use and storage of your personal data. We encourage you to
        read and understand our privacy policy which is available on this
        website or on the link:
        <a href="https://www.motokiki.com/privacy">www.motokiki.com/privacy.</a>
      </p>
      <p>
        As with most websites, we also make use of cookie. It's important to
        understand data held on cookies do not identify who you are or hold
        other personal data about you (i.e. your name, address, etc.). They
        allow us to monitor your use of this website. We welcome you to read and
        understand our cookie policy and it's available on this website or on
        the link:{` `}
        <a href="https://www.motokiki.com/cookie">www.motokiki.com/cookie</a>.
      </p>
      <h4>Our liability to you </h4>
      <p>
        So far as lawfully possible, we exclude all liability to you in contract
        or delict.  We also exclude liability for any consequential or indirect
        loss which may be caused by us, to you. Notwithstanding any other
        provision of these terms and conditions, nothing in these terms and
        conditions shall, or shall be construed as purporting to, exclude or
        restrict liability in respect of death or personal injury for breach of
        duty arising in the course of business or from the occupation of
        business premises.
      </p>
    </div>
  </>
);
export default Cookies;
